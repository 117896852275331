<template>
  <div class="page-div">
    <div class="login-div">
      <div class="left-div"></div>
      <div class="right-div">
        <div class="login-box-div">
          <div class="login-title-div">
            <div class="mark-div"></div>
            <label>欢迎登陆商会管理后台</label>
          </div>
          <div class="item-title-div">账号</div>
          <div class="item-value-div">
            <label class="img-label"><img src="../assets/user.png" height="14" width="14" alt=""/></label>
            <label class="input-label"><input type="text" v-model="acc" placeholder="请输入账号"></label>
          </div>
          <div class="item-title-div pwd-title-div">密码</div>
          <div class="item-value-div">
            <label class="img-label"><img src="../assets/pwd.png" height="17" width="14" alt=""/></label>
            <label class="input-label"><input type="password" v-model="pwd" placeholder="请输入密码"></label>
          </div>
          <div class="code-div" v-if="isShowValidateCode">
            <label class="input-label"><input type="text" v-model="code" placeholder="请输入验证码" /></label>
            <label class="code-img-label"><img class="cursor-el" :src="validateCodeUrl" @click="refreshValidateCode" alt="" /></label>
          </div>
          <div class="login-tips-div text-c err-tips">{{ loginTips }}</div>
          <div class="bottom-div">
            <input type="button" value="登  录" :disabled="logging" @click="onLogin" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import md5 from "js-md5";
import { getValidateAcc, postLogin, verifyCodeImg, getFirmInfo } from "../common/api";

export default {
  name: "Login",
  data() {
    return {
      acc: "",
      pwd: "",
      code: "",
      isShowValidateCode: false,
      validateCodeUrl: null,
      loginTips: "",
      logging: false
    };
  },
  methods: {
    onLogin() {
      if(this.acc.length < 1){
        this.setErrorTipsMsg("请输入账号");
        return;
      }
      if(this.pwd.length < 1){
        this.setErrorTipsMsg("请输入密码");
        return;
      }

      this.logging = true;
      postLogin({
        acc: this.acc,
        pwd: this.$sraEncode(md5(this.pwd)),
        code: this.code
      }).then(data => {
        let user = { isLogin: true };
        user.acc = data.acc;
        user.name = data.name;
        user.token = data.token;
        this.$store.commit("setUser", user);
        this.initData(user);
      }).catch(error => {
        this.logging = false;
        if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
          this.setErrorTipsMsg(error.data.msg);
          if (error.data.status === 1003) {
            this.isShowValidateCode = true;
          }
          if (this.isShowValidateCode) {
            this.refreshValidateCode();
          }
        } else {
          this.setErrorTipsMsg("登录失败，请重试");
        }
      });
    },
    refreshValidateCode() {
      this.validateCodeUrl = verifyCodeImg(this.acc, Math.random());
    },
    setErrorTipsMsg(msg) {
      this.loginTips = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.loginTips = "";
      }, 3000);
    },
    initData(user) {
      getFirmInfo().then(data => {
        user.firm = data;
        this.$store.commit("setUser", user);
        this.$router.push({ path: "/" });
      }).catch(error => {
        this.$store.commit("setUser", { isLogin: false });
        this.logging = false;
        if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
          this.setErrorTipsMsg(error.data.msg);
          if (this.isShowValidateCode) {
            this.refreshValidateCode();
          }
        } else {
          this.setErrorTipsMsg("登录失败，请重试");
        }
      });
    }
  },
  watch: {
    acc: function(newVal, oldVal) {
      if (/^[a-z][a-z_0-9]{4,17}$/.test(newVal)) {
        if (newVal !== oldVal) {
          getValidateAcc({ acc: this.acc }).then(data => {
            if (data === 1) {
              this.isShowValidateCode = false;
            } else {
              if (!this.isShowValidateCode) {
                this.isShowValidateCode = true;
                this.refreshValidateCode();
              }
            }
          });
        }
      } else {
        this.isShowValidateCode = false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.page-div {
  width: 100%;
  height: 100%;
  background-color: #3768FB;
  position: relative;
  .login-div {
    width: 750px;
    height: 378px;
    border-radius: 7px;
    display: flex;
    position: absolute;
    left: calc(50% - 375px);
    top: calc(50% - 200px);
    .left-div {
      width: 385px;
      height: 100%;
      background: url("../../public/img/login-box.png") no-repeat;
      background-size: 100%;
    }
    .right-div {
      flex: 1;
      border-top-right-radius: 7px;
      border-bottom-right-radius: 7px;
      background-color: #ffffff;
      .login-box-div {
        width: 255px;
        height: 320px;
        margin: 30px auto 0 auto;
        position: relative;
        .login-title-div {
          margin-bottom: 25px;
          font-weight: bold;
          font-size: 18px;
          display: flex;
          align-items: center;
          .mark-div {
            width: 2px;
            height: 24px;
            margin-right: 10px;
            background-color: #2f90fd;
          }
        }
        .item-title-div {
          margin-bottom: 10px;
          padding-left: 10px;
          padding-right: 10px;
          font-size: 15px;
          font-weight: bold;
        }
        .pwd-title-div {
          margin-top: 15px;
        }
        .item-value-div {
          height: 33px;
          border: 1px solid #d2d2d2;
          border-radius: 3px;
          background-color: #f9f9f9;
          display: flex;
          align-items: center;
          .img-label {
            margin-left: 10px;
            margin-right: 15px;
            img {
              vertical-align: middle;
            }
          }
          .input-label {
            height: 100%;
            flex: 1;
            input {
              width: 100%;
              height: 100%;
              border: none;
              background-color: #f9f9f9;
            }
          }
        }
        .code-div {
          height: 33px;
          margin-top: 20px;
          display: flex;
          justify-content: center;
          .input-label {
            height: 100%;
            flex: 1;
            input {
              width: calc(100% - 12px);
              height: calc(100% - 2px);
              padding: 0 5px;
              border: 1px solid #d2d2d2;
              border-radius: 3px;
              background-color: #f9f9f9;
            }
          }
          .code-img-label {
            width: 130px;
            height: 100%;
            margin-left: 5px;
            background: #f3f8fd;
            img {
              width: 100%;
              height: 100%;
              border-radius: 5px;
            }
          }
        }
        .login-tips-div {
          margin-top: 10px;
        }
        .bottom-div {
          width: 100%;
          position: absolute;
          bottom: 0;
          text-align: center;
          input {
            width: 100%;
            height: 35px;
            color: white;
            background-color: #2684ed;
            border: none;
            border-radius: 3px;
            padding: 0;
          }
        }
      }
    }
  }
}
</style>
